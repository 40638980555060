<template>
  <el-container class="container">
    <el-container class="main-container">
      <el-header class="header" height="2.70833vw">
        <div class="header-left">
          <div class="header-title">
            <div class="header-title-icon" />
            开发者认证
          </div>
          <!-- <div class="header-btn">开发者认证</div> -->
        </div>
      </el-header>
      <el-main>
        <!-- 标题 -->
        <div class="main-title">认证后即可使用服务发布功能</div>
        <div class="main-box">
          <!-- 左边个人认证 -->
          <div class="main-card">
            <div class="main-card-up">
              <!-- 标签 -->
              <img src="@/assets/workbench/grrz.png" />
              <div class="main-card-up-tag">
                <span class="tag1" v-if="grrz">当前权限</span>
                <span v-else>未认证</span>
              </div>
              <h1 class="main-card-up-title">个人认证开发者</h1>
            </div>
            <div class="main-card-border"></div>
            <div class="main-card-below">
              <h1>•&ensp;适用场景</h1>
              <p>1、公司研发技术选型期</p>
              <p>2、新产品研发、测试阶段</p>
              <p>3、学生研究所或个人兴趣学习研究</p>
              <el-button v-if="!grrz" @click="grrzClick">前往认证</el-button>
            </div>
          </div>
          <!-- 右边企业认证 -->
          <div class="main-card" style="margin-left: 2vw">
            <div class="main-card-up">
              <!-- 标签 -->
              <img src="@/assets/workbench/qyrz.png" />
              <div class="main-card-up-tag">
                <span class="tag1" v-if="qyrz">当前权限</span>
                <span v-else>未认证</span>
              </div>
              <h1 class="main-card-up-title">企业认证开发者</h1>
            </div>
            <div class="main-card-border"></div>
            <div class="main-card-below">
              <h1>•&ensp;适用场景</h1>
              <p>1、有营运资质的企业主体</p>
              <p>2、有较高服务调用需求</p>
              <p></p>
              <el-button v-if="!qyrz" @click="qyrzClick">前往认证</el-button>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'Authentication',
  data() {
    return {
      grrz: true,
      qyrz: false
    };
  },
  created() {},
  methods: {
    grrzClick() {
      this.$store.commit('userData/setPersonalCertificateDialogVisible', true);
    },
    qyrzClick() {
      this.$store.commit(
        'userData/setEnterpriseCertificationDialogVisible',
        true
      );
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  border-radius: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  .main-container {
    border-radius: 10px;
    padding: 20px 28px;
    background-color: #fff;
    // 标题
    .main-title {
      margin-top: 40px;
      text-align: center;
      height: 21px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
    }
    .main-box {
      display: flex;
      justify-content: space-between;
      margin: 50px 10px 0;
    }
    // 左边个人认证
    .main-card {
      overflow: hidden;
      width: 50%;
      height: 515px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      box-shadow: 0px -5px 10px 1px rgba(232, 232, 232, 0.3),
        0px 5px 10px 1px rgba(232, 232, 232, 0.5);
      border-radius: 10px;
      &:hover {
        border: 1px solid #507cff;
        box-shadow: 0px -5px 10px 1px rgba(229, 236, 255, 0.5),
          0px 5px 10px 1px rgba(231, 235, 255, 0.5);
        .main-card-below {
          .el-button {
            visibility: initial;
          }
        }
      }
      &-up {
        overflow: hidden;
        position: absolute;
        width: 745px;
        height: 220px;
        border-radius: 10px 10px 0px 0px;
        img {
          margin: 29px 0 20px 317px;
          width: 110px;
        }
        //标题
        &-title {
          text-align: center;
          height: 19px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 19px;
        }
        //标签
        &-tag {
          width: 140px;
          height: 70px;
          position: absolute;
          top: -30px;
          left: -50px;
          transform: rotate(-45deg);
          span {
            position: absolute;
            bottom: 0;
            display: block;
            font-size: 14px;
            color: #fff;
            line-height: 30px;
            height: 30px;
            background: #cccccc;
            width: 118px;
            text-align: center;
          }
          .tag1 {
            background: #30d998;
            width: 118px;
          }
        }
      }
      &-below {
        position: absolute;
        margin-top: 15px;
        h1 {
          margin-left: 30px;
          padding-bottom: 5px;
          height: 20px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #507cff;
          line-height: 30px;
        }
        p {
          margin-top: 24px;
          margin-left: 65px;
          height: 18px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
        .el-button {
          margin-top: 40px;
          margin-left: 65px;
          width: 130px;
          height: 38px;
          background: #507cff;
          border-radius: 8px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          border: none;
          visibility: hidden;
        }
      }
      &-border {
        margin-top: 220px;
        height: 1px;
        width: 100%;
        background: #eeeeee;
      }
    }
  }
}
</style>
